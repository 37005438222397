<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      v-if="resolutionScreen >= 500"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <b>{{ $t("trashPage.messageboxheader") }}</b
          ><v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">delete</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-icon color="red">folder</v-icon>
          {{ $t("trashPage.messageboxsubheader") }}
        </v-card-text>
        <br />
        <v-card-text v-if="dataAccountActive.type === 'Business'">
          <v-icon color="amber">error_outline</v-icon>
          <b>{{ $t("trashPage.confrimdeleteall2") }}</b>
        </v-card-text>
        <v-card-text v-else >
          <v-icon color="amber">error_outline</v-icon>
          <b>{{ $t("trashPage.confrimdeleteall1") }}</b>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="color.theme"
            @click="$emit('closenoreload')"
            outlined
            :disabled="processdelete"
            >{{ $t("trashPage.canceldelete") }}</v-btn
          >
          <v-btn
            color="red  darken-1 white--text"
            depressed
            @click="$emit('startremove')"
            :loading="processdelete"
            >{{ $t("trashPage.forcedelete") }}</v-btn
          >
        </v-card-actions>
        <!-- <v-progress-linear
          color="green"
          :active="processdelete"
        ></v-progress-linear> -->
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="show"
      v-else
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
    >
      <v-card>
        <v-card-text class="pa-5 text-center">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPageDialog">{{
                $t("dialogconfirmdelete.permanently")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon :color="color.theme" @click="$emit('closenoreload')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <v-icon color="#FAAD14" size="90" class="mb-4"
            >mdi-alert-circle-outline</v-icon
          >
          <br />
          <span :style="titleText">{{ $t("trashPage.messageboxsubheader") }}</span
          ><br />
          <span :style="titleText">{{
            $t("trashPage.confrimdeleteall2")
          }}</span>
          <div class="text-center mt-6">
            <v-btn
              :style="btnAction"
              class="mr-2"
              height="40px"
              width="47%"
              color="red"
              outlined
              @click="$emit('closenoreload')"
              >{{ $t("changeName.close") }}</v-btn
            >
            <v-btn
              class="elevation-0"
              :style="btnAction"
              height="40px"
              width="47%"
              :color="color.theme"
              :dark="color.darkTheme"
              @click="$emit('startremove')"
              >{{ $t("changeName.submit") }}</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="success"
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
    >
      <v-card>
        <v-container fluid class="text-center py-6 mb-n2">
          <v-layout wrap row justify-center>
            <v-flex sm10 xs10> </v-flex>
            <v-flex sm2 xs2 class="ml-n8">
              <v-btn icon :color="color.theme" @click="$emit('closedelete')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-text
          class="mt-n8 pb-10 d-flex flex-column justify-space-between align-center"
        >
          <v-img
            max-width="102"
            max-height="102"
            :src="require('../../assets/img/deletesuccess.png')"
          /><br />
          <span :style="deletesuccess">{{
            $t("dialogconfirmdelete.deletesuccess")
          }}</span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data() {
    return {
      loaddataprogress: false,
      password: "",
      showpassword: false,
    };
  },
  props: ["show", "success", "processdelete"],
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "font-size: 16px; line-height: 24px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษรแสดงการลบเสร็จสิ้น ของ mobile
    deletesuccess() {
      return "color:#58A144; font-size: 18px; line-height: 24px;";
    },
    // style อักษร header ของ mobile
    headerPageDialog() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
};
</script>
<style></style>
